import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text, Box, Badge } from "../../components/Core";

import styled from "styled-components";

import titleImage from "../../assets/image/jpeg/mailbox2.jpg";
import iconQuote from "../../assets/image/png/quote-icon.png";
import styles from '../../assets/styles/blog.module.css';

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin-bottom: 2rem;
    display: block;
  }
`;

const BadgePost = ({ children }) => (
    <Badge
        mr={3}
        mb={3}
        bg="#eae9f2"
        color="#696871"
        fontSize="16px"
        px={3}
        py={2}
    >
        {children}
    </Badge>
);


const BlogDetails = () => {
    return (
        <>
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5"></div>
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="12">
                                <Title variant="hero">
                                    Forward your mail from Australia to all over the world.
                </Title>
                                <Box className="d-flex justify-content-center">
                                    <Text mr={3}>Feb 25, 2021
                  </Text>
                                    <Text>Michael Tippett
                  </Text>
                                </Box>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section className="pb-0 pt-4">
                    <Container>
                        <Row>
                            <Col lg="12" className="mb-5">
                                {/* <!-- Blog section --> */}
                                <Post>
                                    <div>
                                        <img src={titleImage} alt="Mailbox" className={styles.titleImage} />
                                    </div>
                                    <div>
                                        <p>Hotsnail offers the lowest prices for mail forwarding and mail scanning in the industry. GetNetmailbox.com compares our services with other similar business worldwide and come out on top. Check out the list here <a href="https://getnewmailbox.com/" target="_blank">https://getnewmailbox.com/</a>.</p>
                                        <p>We specialize in parcel forwarding and mail scanning more easily and economically for businesses of all sizes who want to maintain their presence in Australia. Hotsnail allows users to shop in Australia and forward their parcel worldwide, access mail just like email, or access snail mail anywhere in the world. Our services are reliable, secured, and spam-free, making us top rated mail service provider. Our organization is well equipped with facilities that help all your mail needs to be handled under one roof. We are trusted by millions of customers in the industry since 2009, this is why hundreds of thousands of members across over 200 countries and territories mail with us.</p>
                                        <p>At hotsnail, we offer 24/7 mailing services from anywhere in the world to perfect our customers' needs. We can as well provide your business with an online PO box and street address for receiving mail and deliveries in Australia. Hotsnail provides a comprehensive mail cloud for organizing snail electronically into your preferred customized folder. With the mail redirection option, hotsnail is great for travelers who may need to redirect their mails.</p>
                                        <p><b>Pricing</b> </p>
                                        <p>With rich quality services at hotsnail, we offer the lowest prices for mail forwarding and scanning in the industry. Our prices are based on weight category, features, and customization choices. Unlike other similar services, hotsnail has plans with no monthly fees. However, we have plans specifically for parcel forwarding with very low upfront costs, only pay for what you forward. With as low as $10, a minimum forward parcel fee, the most reliable and safe delivery is guaranteed. </p>
                                        <p>You can opt for any product of your choice by selecting one of the services we have outlined at <a href="http://www.hotsnail.com.au/">hotsnail</a> to get a custom quote. Or reach us, we will help you build up a plan that fits your plan and budget.</p>
                                        <p><b>How it Works</b></p>
                                        <p>1. First, you will be required to sign up and get your new address. A unique user code and address will then be sent to you.</p>
                                        <p>2. Send your mail directly to thet supplied address, order from Australian shops or redirect your mail with Australian post.</p>
                                        <p>3. After steps 1 and 2 above, you can now manage mail online on your own. You will manage your mail with our state of the art online management system. You will then forward your letters or parcels globally.</p>
                                        <p><b>Hotsnail processes your mail the way you want. You can opt for the automatic option as explored below.</b></p>
                                        <p>1. Scanning everything: hotsnail will open your mail and scan all documents then upload them to our website. Once on the website, you will be able to read, sort, shred, and forward.</p>
                                        <p>2. For selective scanning, hotnail will scan the envelope on your behalf. You will then choose whether you want us to open and scan or forward your item via our mail Management services website. </p>
                                        <p>Overall, hotnail is a true face of digitalism in the mailing industry. Our reliability and safety features remain a choice for many. Join other millions of hotnail users today, and feel the amazing taste of our services at the lowest cost. </p>
                                    </div>
                                    <br></br>
                                    <a href="https://members.hotsnail.com.au/signup">Sign up to HotSnail now</a>

                                </Post>
                                <Box className="d-flex" mt={4}>
                                    <BadgePost>Australian-Products</BadgePost>
                                    <BadgePost>Shopping</BadgePost>
                                    <BadgePost>Mail forwarding</BadgePost>

                                </Box>

                            </Col>
                        </Row>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default BlogDetails;
